<template>
  <div class="form-checkbox" :class="{ 'form-checkbox--checked': checked }">
    <button type="button" @click="handleChange">
      <span class="form-checkbox__button">
        <SfIcon :class="{ 'display-none': !checked }" icon="check" color="white" />
      </span>
      <span class="form-checkbox__label">
        <slot name="label">{{ label }}</slot>
      </span>
    </button>
    <p v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
  },
  name: {
    type: String,
    default: '',
  },
})

const { name } = toRefs(props)
const { checked, handleChange, errorMessage } = useField(name, undefined, {
  type: 'checkbox',
  checkedValue: props.value,
})
</script>

<style lang="scss" scoped>
.form-checkbox {
  button {
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
  }

  &__button {
    height: var(--spacer-base);
    width: var(--spacer-base);
    min-height: var(--spacer-base);
    min-width: var(--spacer-base);
    border: 1px solid var(--black-color);
  }

  &__label {
    margin-left: var(--spacer-sm);
    color: var(--c-link);
  }

  &--checked {
    .form-checkbox__button {
      background: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }

  .sf-icon {
    margin: -2px;
  }
}
</style>
