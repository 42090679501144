<template>
  <span class="sf-chevron" v-bind="$attrs.attrs">
    <slot>
      <span class="sf-chevron__bar sf-chevron__bar--left"></span>
      <span class="sf-chevron__bar sf-chevron__bar--right"></span>
    </slot>
  </span>
</template>
<script setup></script>
<style lang="scss">
@import './styles/atoms/SfChevron.scss';
</style>
