import { storeToRefs } from 'pinia'

export const getProductThumbnailHoverImage = (product) => {
  if (!product || !product.thumbnail_hover) {
    return null
  }
  return product.thumbnail_hover
}

export const getProductColourWheels = (product) => {
  if (!product) {
    return null
  }

  if (product?.colour_wheels && product?.colour_wheels?.length) {
    return product.colour_wheels
  }

  if (product?.configurable_options) {
    const color = product?.configurable_options?.find((option) => option.attribute_code === 'base_color')

    if (color?.values) {
      return color.values.map((c) => ({
        name: c.label,
        image: `${c.swatch_data?.value}`,
        url: product.url_rewrites?.[0]?.url,
      }))
    }
  }

  return null
}

export const getProductOptionsNames = (product): string => {
  const { t } = useI18n()
  if (!product) return ''

  const translatedLabels = {
    size: t('variantMatrixSize'),
    base_color: t('variantMatrixColor'),
    birth_year: t('variantMatrixBirthYear'),
    birth_month: t('variantMatrixBirthMonth'),
    zodiac_sign: t('variantMatrixZodiacSign'),
    initial: t('variantMatrixInitial'),
    giftcard_price: t('variantMatrixGiftcardPrice'),
    phone_type: t('variantMatrixPhoneModel'),
  }

  const names =
    product.configurable_options
      ?.filter((option) => option.values.length > 1 || option.attribute_code === 'phone_type')
      ?.map((option) => translatedLabels[option.attribute_code]) || []
  if (product.options?.length) {
    names.push(t('variantMatrixEngraving'))
  }
  return names.join(' & ')
}

export const getSelectedProductVariant = (product, productConfiguration) => {
  if (!product) return null
  // Returns variant product if there is one, otherwise the main product
  if (!productConfiguration) return product.sku
  const selectedProduct = product.variants?.find((variant) =>
    Object.values(productConfiguration)?.every((option) =>
      variant.attributes.find((attribute) => attribute.uid === option),
    ),
  )
  return selectedProduct?.product || { ...product, id: product.pid }
}

export const getSelectedProductVariantByUid = (sku, productConfiguration, attributeUid, uid) => {
  const { products } = storeToRefs(useProductStore())
  const product = products.value[sku]
  const configuration = {
    ...productConfiguration,
    [attributeUid]: uid,
  }
  const selectedProduct = getSelectedProductVariant(product, configuration)
  return selectedProduct || { ...product, id: product.pid }
}

const productGetters = {
  getProductThumbnailHoverImage,
  getProductColourWheels,
  getProductOptionsNames,
  getSelectedProductVariant,
  getSelectedProductVariantByUid,
}

export default productGetters
