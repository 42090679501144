export const stripTags = (content: string) => content.replace(/(<([^>]+)>)/gi, '')

export const truncateWords = (content: string, words?: number) =>
  content
    .split(' ')
    .slice(0, words || 25)
    .join(' ') + (content.split(' ').length > (words || 25) ? '...' : '')

export const getNumericPriceFromString = (str: string) =>
  parseFloat(str.match(/[+-]?([0-9]*[.,])?[0-9]+/)?.[0]).toFixed(2)

export const kebabize = (str) =>
  str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, (c: string, i: number) => (i ? '-' : '') + c.toLowerCase())

export const slugify = (str) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-') // Replace & with '-'
    .replace(/öß/g, 'oess') // Replace öß with 'oess'
    .replace(/ß/g, 'ss') // Replace ß with 'ss'
    .replace(/ä/g, 'ae') // Replace ä with 'ae'
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
    .replace(/-und-/, '-')
}

export const unescape = (html: string) => {
  if (!import.meta.server) {
    const divElement = document.createElement('div')
    divElement.innerHTML = html
    return divElement.textContent || ''
  }

  let r = html
  r = r.replace(/&nbsp;/gi, ' ')
  r = r.replace(/&amp;/gi, '&')
  r = r.replace(/&quot;/gi, `"`)
  r = r.replace(/&lt;/gi, '<')
  r = r.replace(/&gt;/gi, '>')
  return r
}

export const stripSpecificTags = (html: string, tags: string[]) => {
  let text = html

  tags.forEach((tag) => {
    const tagRegex = new RegExp(`<${tag}.*>.*?<\/${tag}>`, 'gi')
    text = text.replaceAll(tagRegex, '')
  })
  return text
}

export const parseCookieString = (cookieString: string) => {
  return cookieString
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})
}

export default stripTags
