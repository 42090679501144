<template>
  <Multiselect
    ref="multiSelect"
    class="product-variant-multiselect"
    :value="value"
    :options="optionList"
    :required="true"
    :disabled="disabled"
    :placeholder="placeholder"
    :canClear="false"
    :canDeselect="false"
    :closeOnSelect="false"
    @close="clearPointer"
    @change="selectItem"
  >
    <template v-slot:option="{ option }">
      <span class="multiselect-option-content" @click="selectOutOfStockItem(option.value)">
        {{ option.label }}
        <BellIcon v-if="option.disabled" />
      </span>
    </template>
  </Multiselect>
</template>

<script setup>
import Multiselect from '@vueform/multiselect'
import BellIcon from '~/assets/icons/bell.svg'

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  valid: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: 'This field is not correct.',
  },
  value: {
    type: [String, Number],
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['change', 'selectOutOfStockItem'])

const multiSelect = ref()

const optionList = computed(() => {
  return props.options.map((option) => {
    return {
      label: option.label,
      value: option.value,
      trackBy: option.label,
      disabled: option.disabled,
    }
  })
})

const selectItem = (uid) => {
  setTimeout(() => emit('change', uid), 0)
  multiSelect.value.close()
}

const selectOutOfStockItem = (uid) => {
  emit('selectOutOfStockItem', uid)
  multiSelect.value.close()
}

const clearPointer = () => multiSelect.value.setPointer(null)
</script>
<style lang="scss">
@import '@vueform/multiselect/themes/default.css';

.product-variant-multiselect {
  --ms-ring-color: #fff;
  --ms-option-bg-selected: #ffffff;
  --ms-option-bg-selected-pointed: #f3f4f6;
  --ms-option-color-selected: ;
  --ms-option-color-selected-pointed: #1f2937;
}

.multiselect-dropdown {
  z-index: 3;
}

.multiselect-caret {
  z-index: 4;
}

.multiselect-option {
  &.is-disabled {
    cursor: pointer;
    color: var(--gray-secondary-color);
    &:hover {
      background: var(--ms-option-bg-pointed, #f3f4f6);
    }
    svg {
      color: #000;
      margin-left: auto;
    }
  }
}

.multiselect-option-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
