export const clickOutside = {
  mounted(el, binding) {
    binding.instance.name = 'click-outside'
    const closeHandler = binding.value
    el._outsideClickHandler = (event) => {
      if (!el.contains(event?.target)) {
        event.stopPropagation()
        closeHandler(event)
      }
    }
    document.addEventListener('mousedown', el._outsideClickHandler)
    document.addEventListener('touchstart', el._outsideClickHandler)
  },
  beforeUnmount(el) {
    document.removeEventListener('mousedown', el._outsideClickHandler)
    document.removeEventListener('touchstart', el._outsideClickHandler)
    el._outsideClickHandler = null
  },
}
