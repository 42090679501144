export const formatUrl = (url: string) => {
  const config = useRuntimeConfig()
  const { locales } = useI18n()
  const localePath = useLocalePath()
  const knownDomains = [
    'myj-test.redboxcloud.com',
    'tst.my-jewellery.com',
    'stg.my-jewellery.com',
    'www.my-jewellery.com',
  ]

  if (typeof url !== 'string') {
    return localePath('')
  }

  // Doesn't match store URL
  if (url.indexOf(config.public.storeUrl) === -1) {
    const localeCodes = locales.value.map((locale) => locale.code)
    const localeRegex = new RegExp(`^\/(${localeCodes.join('|')})\/`)

    // If pointing to wrong MyJewellery domain, change url to current domain
    if (knownDomains.some((domain) => url.includes(domain))) {
      return knownDomains.reduce((acc, domain) => acc.replace(new RegExp(`(https?:\/\/)?(www\.)?${domain}`), ''), url)
    }

    if (localeRegex.test(url) || url.startsWith('https://') || url.startsWith('www.')) {
      return url
    }

    return localePath(url)
  }

  if (!url.startsWith('https://') && !url.startsWith('www.')) {
    return url.replace(config.public.storeUrl, '')
  }
  return url
}

export default formatUrl
