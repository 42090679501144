<template>
  <label v-if="saleLabel" class="sale-label">
    {{ saleLabel }}
  </label>
</template>

<script setup>
const props = defineProps({
  originalPrice: {
    type: [String, Number],
    default: null,
    required: false,
  },
  discountPrice: {
    type: [String, Number],
    default: null,
    required: false,
  },
})

const saleLabel = computed(() => {
  if (!props.discountPrice || props.discountPrice === '' || !props.originalPrice) return
  const originalPriceFloat =
    typeof props.originalPrice === 'string'
      ? parseFloat(props.originalPrice.replace(',', '.').replace(/^\D+/g, ''))
      : props.originalPrice
  const discountPriceFloat =
    typeof props.discountPrice === 'string'
      ? parseFloat(props.discountPrice.replace(',', '.').replace(/^\D+/g, ''))
      : props.discountPrice
  const percentageDiscount = Math.round(((originalPriceFloat - discountPriceFloat) / originalPriceFloat) * 100)

  if (percentageDiscount === 0) {
    return
  }

  return `-${percentageDiscount}%`
})
</script>

<style lang="scss" scoped>
.sale-label {
  display: flex;
  align-items: center;
  padding: 0 var(--spacer-2xs);
  min-height: 20px;
  background-color: var(--black-color);
  color: var(--white-color);
  font-size: var(--font-size-10);
  font-weight: var(--font-weight--semibold);

  @include for-desktop {
    font-size: var(--font-size--sm);
    min-height: 24px;
    padding: 0 var(--spacer-xs);
  }
}

// Don't show the label in the search results
.results-listing .sale-label {
  display: none;
}
</style>
