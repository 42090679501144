<template>
  <SfButton
    :class="['sf-button--pure sf-color', { 'is-active': selected }]"
    :style="{
      '--color-background': color,
    }"
    :aria-pressed="selected?.toString()"
    v-bind="$attrs.attrs"
  >
    <slot name="badge">
      <SfBadge
        :class="{
          'display-none': !hasBadge || (!selected && hasBadge),
        }"
        class="sf-color__badge smartphone-only"
      >
        <SfIcon size="16px" color="white" icon="check" aria-hidden="true" />
      </SfBadge>
    </slot>
  </SfButton>
</template>
<script>
export default defineComponent({
  name: 'SfColor',
  props: {
    color: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    hasBadge: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
<style lang="scss">
@import './styles/atoms/SfColor.scss';
</style>
