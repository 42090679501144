<template>
  <span class="sf-badge">
    <slot />
  </span>
</template>

<script setup></script>

<style lang="scss">
@import './styles/atoms/SfBadge.scss';
</style>
