<template>
  <ul class="sf-list">
    <slot />
  </ul>
</template>
<script setup></script>
<style lang="scss">
@import './styles/organisms/SfList.scss';

.sf-list {
  mark {
    background: none;
    color: var(--cta-pink-color);
  }

  .sf-list__item {
    --list-item-padding: 0;
    --list-item-color: var(--black-color);
    --list-item-font-family: var(--font-family--primary);
    --list-item-font-size: var(--font-size-xs);
    --list-item-font-line-height: var(--spacer-18);
    --list-item-border-width: 0;

    @include for-desktop {
      --list-item-font-size: var(--font-size-sm);
      --list-item-font-line-height: var(--spacer-sm);
    }
  }

  .sf-menu-item {
    --menu-item-color: var(--black-color);
    --menu-item-font-family: var(--font-family--primary);
    --menu-item-font-size: var(--font-size-xs);
    --menu-item-font-line-height: var(--spacer-18);
    --menu-item-count-margin: 0 0 0 var(--spacer-2xs);
    --menu-item-count-color: var(--black-color);
    --menu-item-label-color: var(--black-color);
    --button-height: auto;

    &a:hover {
      --menu-item-font-weight: var(--font-weight--semibold);
      --menu-item-label-color: var(--cta-pink-color);
    }

    &button {
      --menu-item-count-margin: 0;

      .sf-link {
        &:hover {
          --link-font-weight: var(--font-weight--semibold);
          --link-color: var(--cta-pink-color);
        }
      }
    }

    @include for-desktop {
      --menu-item-font-size: var(--font-size-sm);
      --menu-item-font-line-height: var(--spacer-md);
    }
  }

  .sf-link {
    --link-padding: 0;
    --link-color: var(--black-color);
    --link-font-family: var(--font-family--primary);
    --link-font-size: var(--font-size-xs);
    --link-font-line-height: var(--spacer-18);
    --link-text-decoration: none;

    @include for-desktop {
      --link-font-size: var(--font-size-sm);
      --link-line-height: var(--spacer-md);
    }

    &:hover {
      font-weight: var(--font-weight--semibold);
    }
  }
}
</style>
