import type { GetProductSearchParams } from '@vue-storefront/magento-types'
import type { MJProductDetails, ProductList, UseProductErrors, UseProductInterface } from './useProduct'

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const logger = useAppLogger("useProduct")
  const { $sdk } = useNuxtApp()
  const loading = ref(false)
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductDetails: null,
  })

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    logger.debug(`useProduct/${id}/getProductList`, searchParams)
    let products: ProductList = null

    try {
      loading.value = true
      const { data } = await $sdk.magento.products(searchParams, {
        products: 'get-products',
      })
      products = data?.products
      error.value.getProductList = null
    } catch (err) {
      error.value.getProductList = err
      logger.error(`useProduct/${id}/search`, err)
    } finally {
      loading.value = false
    }

    return products
  }

  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<MJProductDetails | null> => {
    logger.debug(`useProduct/${id}/getProductDetails`, searchParams)

    try {
      const { data } = await $sdk.magento.productDetails(searchParams, {
        productDetails: 'product-details-custom-query',
      })
      const product: MJProductDetails = data?.products
      error.value.getProductDetails = null

      return product
    } catch (err) {
      error.value.getProductDetails = err
      logger.error(`useProduct/${id}/search`, err)
    } finally {
      loading.value = false
    }
  }

  return {
    getProductList,
    getProductDetails,
    error: readonly(error),
    loading: readonly(loading),
  }
}

export * from './useProduct'
export default useProduct
